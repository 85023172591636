import React from 'react';

import GenerateLink from '@components/GenerateLink';

function GenerateLinkWrapper() {
  return (
      <div>{<GenerateLink />}</div>
  );
}

export default GenerateLinkWrapper;
