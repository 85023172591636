import React, { useState, Fragment } from 'react';
import useGuestData from '@hooks/useGuestData';
import { Container, Heading, Text, Button, VStack, Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption } from '@chakra-ui/react'

const PERSONAL = 1;
const ALL = 2;

function GenerateLink() {
  const [type, setType] = useState(ALL);
  const [name, setName] = useState();
  const [showResult, setShowResult] = useState(false);
  const [successCopy, setSuccessCopy] = useState(false);

  const { data, loading } = useGuestData();

  const URL = `https://triana.singgih.me?id=${encodeURIComponent(name)}`;

  const handleSetName = (e) => {
    setName(e.target.value);
  };

  const handleCopy = async (text, showAlert = false) => {
    try {
      await navigator.clipboard.writeText(text);
      setSuccessCopy(true);
      showAlert && alert('Berhasil');
    } catch (err) {
      setSuccessCopy(false);
      alert('Failed to copy! :(');
    }
  };

  const renderContentType = () => {
    if (type === PERSONAL) {
      return (
        <Fragment>
          <div class="form-group">
            <label for="exampleInputEmail1">Nama Tamu</label>
            <input
              value={name}
              onChange={handleSetName}
              type="text"
              class="form-control"
              placeholder="Nama tamu.."
            ></input>
          </div>
          <Button colorScheme='teal' size='sm' type="submit" onClick={() => setShowResult(true)}>
            Generate Link
          </Button>
        </Fragment>
      );
    }

    if (type === ALL) {
      return (
        <Fragment>
					<VStack align='center'>
						<Button colorScheme='teal' size='sm' mt={4} type="submit" onClick={() => setShowResult(true)}>
							Generate Link
						</Button>
					</VStack>
        </Fragment>
      );
    }
  };

  const renderResult = () => {
    if (!showResult) return null;

    if (type === PERSONAL) {
      return (
        <div className="col-md-4 col-md-offset-4">
          <div class="alert alert-success" role="alert" style={{ marginTop: '20px' }}>
            <strong>Berhasil!</strong> <br />
            <a href={URL} target="_blank" rel="noreferrer" style={{ color: 'green', textDecoration: 'underline' }}>
              {URL}
            </a>
            <button
              type="button"
              className="btn btn-default btn-xs"
              style={{ marginLeft: '8px' }}
              onClick={() => handleCopy(URL)}
            >
              {successCopy ? 'Tersalin' : 'Salin'}
            </button>
          </div>
        </div>
      );
    }

    if (type === ALL) {
      return (
				<Box overflowX="auto">
					<Table variant='striped' size='sm' mt={5}>
						<TableCaption>Guest Link</TableCaption>
						<Thead>
							<Tr>
								<Th fontFamily='body'>No</Th>
								<Th fontFamily='body'>Nama</Th>
								<Th fontFamily='body'>Type</Th>
								<Th fontFamily='body'>Language</Th>
								<Th fontFamily='body'>Link</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data.map((d, index) => {
								const guestLang = d.lang == 'en' ? '' : '/id/'
								const mapURL = `https://triana.singgih.me${guestLang}?id=${encodeURIComponent(d.id)}`;
								let textCopy = ''
								if (d.isInvitation) {
									textCopy = `Bismillahirrahmanirrahim

Assalamu’alaikum warahmatullahi wabarakatuh

_Dengan memohon rahmat dan ridho Allah SWT, kami bermaksud untuk menyampaikan kabar gembira dan memohon doa restu Bapak/Ibu/Saudara/i serta teman-teman kami untuk acara pernikahan putra-putri kami:_

_*Rizki Triana Sari (Triana)*_
Putri ketiga dari Bpk. Mustafa & Ibu Nurul Hidayah

&

_*Singgih Adi Nugroho (Singgih)*_
Putra pertama dari Bpk. Sukamto(Alm.) & Ibu Winarsih

Yang Insya Allah akan dilaksanakan pada:

Akad :
Hari/tanggal : Minggu 20 Maret 2022
Tempat : Madison Avenue, Cilegon
Pukul : 09.00 WIB-Selesai

Resepsi :
Hari/tanggal : Minggu 20 Maret 2022
Bertempat : Madison Avenue, Cilegon
Pukul : 11.00-14.00 WIB

Berikut link undangan digital

`+ mapURL + `

Merupakan suatu kehormatan dan kebahagiaan bagi kami dan keluarga kami apabila Bapak/Ibu/Saudara/i dan teman-teman, berkenan hadir dan memberikan doa untuk keberkahan pernikahan anak kami.

Tanpa mengurangi rasa hormat, sesuai anjuran pemerintah terkait upaya pencegahan Covid-19, selama pelaksanaan acara tamu undangan dimohon untuk menerapkan protokol kesehatan.

Terima kasih.

Wassalamu'alaikum Warahmatullahi Wabarakatuh

Kami yang berbahagia,

*TRIANA & SINGGIH*`
								} else {
									textCopy = `Bismillahirrahmanirrahim

Assalamu'alaikum Warahmatullahi Wabarakatuh

_Dengan memohon rahmat dan ridho Allah SWT, kami bermaksud untuk menyampaikan kabar gembira dan memohon doa restu Bapak/Ibu/Saudara/i serta teman-teman kami untuk acara pernikahan putra-putri kami:_

_*Rizki Triana Sari (Triana)*_
Putri ketiga dari Bpk. Mustafa & Ibu Nurul Hidayah

&

_*Singgih Adi Nugroho (Singgih)*_
Putra pertama dari Bpk. Sukamto(Alm.) & Ibu Winarsih

Yang Insya Allah akan dilaksanakan pada:

Hari/tanggal: Minggu, 20 Maret 2022
Akad nikah: 09.00 WIB - selesai

_Tanpa mengurangi rasa hormat, acara pernikahan kami memberlakukan protokol kesehatan yang ketat, seperti pembatasan jumlah tamu undangan. Dalam rangka mematuhi protokol kesehatan tersebut, dengan berat hati kami tidak dapat mengundang Bapak/Ibu/Saudara/i ke dalam acara kami. Semoga kita semua tetap bisa menjalin silaturahmi dengan kedua keluarga mempelai melalui media online dengan keadaan sehat._

_Merupakan suatu kehormatan dan kebahagiaan bagi kami dan keluarga kami apabila Bapak/Ibu/Saudara/i berkenan memberikan doa restu untuk kelancaran pernikahan kami. Atas perhatian dan doa restunya kami ucapkan terima kasih._

`+ mapURL + `

Wassalamu'alaikum Warahmatullahi Wabarakatuh

Kami yang berbahagia,

*TRIANA & SINGGIH*`
								}
								return (
								<Tr key={d.id}>
									<Td>{index + 1}</Td>
									<Td>{d.name}</Td>
									<Td>{d.isInvitation ? 'Invitation' : 'Announcement'}</Td>
									<Td>{d.lang}</Td>
									<Td>
										<a href={mapURL} target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>
											{mapURL}
										</a>
										<Button
											size='xs'
											colorScheme='green'
											ml={2}
											onClick={() => handleCopy(textCopy, true)}
										>
											copy
										</Button>
									</Td>
								</Tr>
								);
							})}
						</Tbody>
					</Table>
				</Box>
      );
    }
  };

  return (
		<Container maxW='container.xl'>
			<VStack spacing={4} align='center'>
				<Text>Link Generator</Text>
				<Heading>Triana & Singgih</Heading>
			</VStack>

			{loading && <h4 style={{ textAlign: 'center' }}>Memuat data..</h4>}

			{!loading && (
				<Fragment>
					<div className="row">
						<div className="col-md-4 col-md-offset-4">
							{renderContentType()}
						</div>
					</div>
					<div className="row">{renderResult()}</div>
				</Fragment>
			)}
		</Container>
  );
}

export default GenerateLink;
